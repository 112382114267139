import { useEffect, useState } from 'react';
import styles from './login.module.scss';
import Selnikel from '../../../Assets/Images/Logos/selnikel.png';
import InputField from '../../../Components/InputField/InputField';
import Button from '../../../Components/Button/Button';
import AuthService from '../../../Services/auth.service';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useRecoilState } from 'recoil';
import { loadingState } from '../../../Recoil/Atoms';

const Login = () => {
    const navigate = useNavigate();
    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [, setLoading] = useRecoilState(loadingState);

    useEffect(() => {
        var response = AuthService.isLogged();
        if (response.auth) {
            if (response.role === 'Admin') {
                navigate('/admin/events');
            }
        }
    });

    function handleLogin() {
        setLoading(true);
        AuthService.login(username, password)
            .then((response) => {
                if (response.status === 200) {
                    const data = response.data;
                    if (data.role === 'Admin') {
                        navigate('/admin/events');
                    }
                }
            })
            .catch((error) => {
                console.error('Error during login:', error);
                alert('An error occurred during login. Please try again.');
            })
            .finally(() => {
                setLoading(false);
            });
    }
    return (
        <>
            <Helmet>
                <title>Selnikel</title>
                <meta name='description' content='Login to admin panel.' />
                <link rel='canonical' href='/login' />
            </Helmet>
            <div className={styles.container}>
                <div className={styles.wrapper}>
                    <a href='/'>
                        <img src={Selnikel} alt='Selnikel' />
                    </a>
                    <div className={styles.card}>
                        <InputField
                            label='Username'
                            path='user.png'
                            placeholder='Enter username'
                            id='username'
                            name='username'
                            value={username}
                            onChange={(e) => setUserName(e.target.value)}
                            maxLength='25'
                        />
                        <InputField
                            label='Password'
                            path='lock.png'
                            placeholder='Enter password'
                            type='password'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            maxLength='25'
                        />
                    </div>
                    <Button buttonText='Login' onClick={handleLogin} />
                    <p className={styles.footerTag}>
                        Copyright © {new Date().getFullYear()} Selnikel. All rights reserved.
                    </p>
                </div>
            </div>
        </>
    );
};

export default Login;
