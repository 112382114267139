import { useEffect, useState } from 'react';
import styles from './order-confirmation.module.scss';
import Selnikel from '../../../Assets/Images/Logos/selnikel.png';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { quoteResponse } from '../../../Services/api.service';
import Loader from '../../../Components/Loader/Loader';
import check from '../../../Assets/Images/Icons/Other/check.png';
import xmark from '../../../Assets/Images/Icons/Other/close.png';

const OrderConfirmation = () => {
    const params = useParams();
    const [accepted, setAccepted] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            await quoteResponse(params.id, params.r);

            if (params.r === 'approve') {
                setAccepted(true);
            }

            setLoading(false);
        })();
    });

    return (
        <>
            <Helmet>
                <title>Selnikel - Order Confirmation</title>
                <meta name='description' content='Order Confirmation' />
                <link rel='canonical' href='/order-confirmation' />
            </Helmet>
            {loading ? (
                <Loader />
            ) : (
                <div className={styles.container}>
                    <div className={styles.wrapper}>
                        <a href='/'>
                            <img src={Selnikel} alt='Selnikel' />
                        </a>
                        <div className={styles.card}>
                            {accepted ? (
                                <>
                                    <img src={check} alt='congrtz' />
                                    <p>
                                        Thank you for accepting our quote, we are going to start
                                        working on your product as soon as possible.
                                    </p>
                                    <p>
                                        You will shortly be receiving another email with more
                                        details for your order.
                                    </p>
                                </>
                            ) : (
                                <>
                                    <img src={xmark} alt='decline' />
                                    <p>
                                        We understand your decision, and we’re here to support you.
                                    </p>
                                    <p>
                                        Please don’t hesitate to reach out if there’s anything we
                                        can assist with in the future.
                                    </p>
                                </>
                            )}
                        </div>
                        <p className={styles.footerTag}>
                            Copyright © {new Date().getFullYear()} Selnikel. All rights reserved.
                        </p>
                    </div>
                </div>
            )}
        </>
    );
};

export default OrderConfirmation;
