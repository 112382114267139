import { useState } from 'react';
import styles from './quote.module.scss';
import Selnikel from '../../../Assets/Images/Logos/selnikel.png';
import InputField from '../../../Components/InputField/InputField';
import Button from '../../../Components/Button/Button';
import { Helmet } from 'react-helmet-async';
import { requestQuote } from '../../../Services/api.service';

const Quote = () => {
    const [rakim, setRakim] = useState('');
    const [sicaklik, setSicaklik] = useState('');
    const [debi, setDebi] = useState('');
    const [basinc, setBasinc] = useState('');
    const [email, setEmail] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [loading, setLoading] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');

    const submitButtonHandler = async () => {
        setSubmit(true);
        setLoading(true);
        await requestQuote({
            rakim: rakim,
            sicaklik: sicaklik,
            debi: debi,
            basinc: basinc,
            companyName: companyName,
            email: email,
        })
            .then((response) => {
                if (response.status === 200) {
                    setResponseMessage(response.data.message);
                }
            })
            .catch((error) => {
                if (error.response.status === 405) {
                    setResponseMessage(error.response.data.message);
                } else {
                    setResponseMessage('There was a problem generating your quote.');
                }
            });
        setTimeout(() => {
            setLoading(false);
        }, 500);
    };

    return (
        <>
            <Helmet>
                <title>Selnikel</title>
                <meta name='description' content='Login to admin panel.' />
                <link rel='canonical' href='/login' />
            </Helmet>
            <div className={styles.container}>
                <div className={styles.wrapper}>
                    <a href='/'>
                        <img src={Selnikel} alt='Selnikel' />
                    </a>

                    <InputField
                        label='Rakim (m)'
                        type='text'
                        value={rakim}
                        onChange={(e) => setRakim(e.target.value)}
                        maxLength='25'
                    />

                    <InputField
                        label='Sicaklik (°C)'
                        type='text'
                        value={sicaklik}
                        onChange={(e) => setSicaklik(e.target.value)}
                        maxLength='25'
                    />

                    <InputField
                        label='Debi (m3/s)'
                        type='text'
                        value={debi}
                        onChange={(e) => setDebi(e.target.value)}
                        maxLength='25'
                    />

                    <InputField
                        label='Basinc (Pa)'
                        type='text'
                        value={basinc}
                        onChange={(e) => setBasinc(e.target.value)}
                        maxLength='25'
                    />

                    <InputField
                        label='Company Name'
                        type='text'
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        maxLength='25'
                    />

                    <InputField
                        label='Email'
                        type='text'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        maxLength='25'
                    />

                    <Button
                        buttonText='Submit'
                        submitted={submit}
                        loading={loading}
                        responseMessage={responseMessage}
                        onClick={() => submitButtonHandler()}
                    />
                </div>
            </div>
        </>
    );
};

export default Quote;
