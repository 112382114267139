import React, { useEffect, useState } from 'react';
import Scheduler, { SchedulerData, ViewTypes, DATE_FORMAT } from 'react-big-scheduler';
import moment from 'moment';
import withDndContext from './withDndContext';
import 'react-big-scheduler/lib/css/style.css';
import { createEvent, getEvents, getStations, getStation, deleteAllEvents } from '../../Services/api.service';
import Loader from '../Loader/Loader';
import { dateToUnixTimeStamp, unixTimestampToDateTime } from '../DatePicker/DatePicker';
import { v4 as uuidv4 } from 'uuid';
import Button from '../Button/Button';
import styles from './schedulercomp.module.scss';
import './schedulercomponent.css';

const COLOR_LIST = ['#F43545', '#FF8931', '#FAD717', '#00BA71', '#00C2DE', '#00418D', '#5F2879'];

const SchedulerComponent = (props) => {
    const [refreshEvents, setRefreshEvents] = useState(false);
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [resources, setResources] = useState([]);
    const [groupedEvents, setGroupedEvents] = useState([]);
    const [deliveryDate, setDeliveryDate] = useState('');
    const [autoCreateTriggered, setAutoCreateTriggered] = useState(false);

    const [schedulerData, setSchedulerData] = useState(
        new SchedulerData(moment().format(DATE_FORMAT), ViewTypes.Week, false, false, {
            displayWeekend: false,
            weekCellWidth: '16%',
            schedulerWidth: '95%',
            resourceName: 'İstasyonlar',
            dayStartFrom: 8,
            dayStopTo: 16,
            views: [
                {
                    viewName: 'Gün',
                    viewType: ViewTypes.Day,
                    showAgenda: false,
                    isEventPerspective: false,
                },
                {
                    viewName: 'Hafta',
                    viewType: ViewTypes.Week,
                    showAgenda: false,
                    isEventPerspective: false,
                },
            ],
        }),
    );

    useEffect(() => {
        const orderColorMap = {};

        // Function to get or assign color based on order ID
        const getColorForOrderId = (orderId) => {
            if (!orderColorMap[orderId]) {
                // Assign a new color if not already assigned
                const colorIndex = Object.keys(orderColorMap).length % COLOR_LIST.length;
                orderColorMap[orderId] = COLOR_LIST[colorIndex];
            }
            return orderColorMap[orderId];
        };

        (async () => {
            const currentEvents = await getEvents(true);

            // Format the events
            const formattedEvents = await Promise.all(
                currentEvents['events'].map(async (event) => ({
                    ...event,
                    bgColor: getColorForOrderId(event.orderId),
                    resourceId: await getSlotIdBasedOnResourceId(event.resourceId),
                    start: unixTimestampToDateTime(event.start),
                    end: unixTimestampToDateTime(event.end),
                })),
            );

            // Set the events
            setEvents(formattedEvents);
            setGroupedEvents(groupAndSortEvents(formattedEvents));

            // Filter resources based on allowedResources prop
            let fetchedResources = currentEvents['stations'];

            let formattedResources = fetchedResources.map((resource) => ({
                id: resource.stationNo,
                name: `${resource.title} (${resource.type})`,
            }));

            if (props.allowedResources.length > 0) {
                formattedResources = formattedResources.filter((resource) => {
                    const match = resource.name.match(/\(([^)]+)\)/);
                    if (match && match[1]) {
                        return props.allowedResources.includes(match[1]);
                    }
                    return false;
                });
            }

            setResources(formattedResources);
            if (!autoCreateTriggered) {
                setLoading(false);
            }
        })();
        // eslint-disable-next-line
    }, [props, refreshEvents]); // Listen to refreshEvents

    useEffect(() => {
        if (resources.length > 0) {
            const newSchedulerData = Object.assign(
                Object.create(Object.getPrototypeOf(schedulerData)),
                schedulerData,
            );

            newSchedulerData.setResources(resources);
            newSchedulerData.setEvents(events);
            setSchedulerData(newSchedulerData);

            getDeliveryDate();
        }
        // eslint-disable-next-line
    }, [resources, events]);

    const prevClick = () => {
        schedulerData.prev();
        setSchedulerData(
            Object.assign(Object.create(Object.getPrototypeOf(schedulerData)), schedulerData),
        );
        setRefreshEvents((prev) => !prev);
    };

    const nextClick = () => {
        schedulerData.next();
        setSchedulerData(
            Object.assign(Object.create(Object.getPrototypeOf(schedulerData)), schedulerData),
        );
        setRefreshEvents((prev) => !prev);
    };

    const onViewChange = (_, view) => {
        schedulerData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective);
        setSchedulerData(
            Object.assign(Object.create(Object.getPrototypeOf(schedulerData)), schedulerData),
        );
        setRefreshEvents((prev) => !prev);
    };

    const onSelectDate = (_, date) => {
        const newSchedulerData = new SchedulerData(
            moment(date).format(DATE_FORMAT),
            schedulerData.viewType,
            schedulerData.events,
            schedulerData.resources,
        );
        setSchedulerData(newSchedulerData); // Update the scheduler data to reflect the selected date
    };

    const getResourceDictForCurrentAgenda = () => {
        let resultDict = {};
        schedulerData.resources.forEach((element) => {
            const match = element['name'].match(/\(([^)]+)\)/);
            const result = match ? match[1] : null;
            resultDict[element['id']] = result;
        });
        return resultDict;
    };

    const getResourceIdBasedOnSlotId = async (slotId) => {
        const stations = await getStations();
        const station = stations.find((s) => s.stationNo === slotId);
        return station.id;
    };

    const getSlotIdBasedOnResourceId = async (resourceId) => {
        const station = await getStation(resourceId);
        return station.stationNo;
    };
    
    const extractHoursForStations = (product) => {
        let outputDict = {};

        for (let key in product) {
            if (product.hasOwnProperty(key) && key.endsWith('Hours')) {
                let newKey = key.slice(0, -5);
                outputDict[newKey] = product[key];
            }
        }

        let resultDict = [];

        for (let key in outputDict) {
            resultDict.push({
                ids: getIdsByKeyword(key),
                key: key,
                hour: outputDict[key],
            });
        }
        return resultDict;
    };

    const getIdsByKeyword = (keyword) => {
        const ids = [];

        for (const key in resources) {
            if (resources.hasOwnProperty(key)) {
                const name = resources[key].name;
                // Use regex to find the word in parentheses
                const match = name.match(/\((.*?)\)/);
                if (match && match[1].trim() === keyword) {
                    ids.push(resources[key].id);
                }
            }
        }

        return ids;
    };

    const groupAndSortEvents = (rawItems) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Set to the start of the current day

        const grouped = rawItems
            .filter((item) => {
                const startDate = new Date(item.start);
                const endDate = new Date(item.end);

                // Include events that start today or in the future, or have already started but end in the future
                return startDate >= today || endDate >= today;
            })
            .reduce((acc, item) => {
                const id = item.resourceId;

                if (!acc[id]) {
                    acc[id] = [];
                }

                acc[id].push(item);

                return acc;
            }, {});

        // Sort items in each group from oldest to newest
        Object.keys(grouped).forEach((key) => {
            grouped[key].sort((a, b) => new Date(a.start) - new Date(b.start));
        });

        // Convert the grouped object into an array of dictionaries
        const result = Object.keys(grouped).map((key) => ({
            resourceId: key,
            items: grouped[key],
        }));
        return result;
    };

    const newEvent = async (_, slotId, __, start) => {
        const resourceDict = getResourceDictForCurrentAgenda();
        let remainingHours = parseInt(props.product[resourceDict[slotId] + 'Hours'], 10);
        const originalHours = remainingHours;

        let startDate = new Date(start);
        startDate.setHours(8, 0, 0, 0);

        const endDate = endDateCalculator(startDate, remainingHours);

        const event = {
            id: uuidv4(),
            orderId: props.order.id,
            title: props.order.orderNumber,
            start: dateToUnixTimeStamp(startDate),
            end: dateToUnixTimeStamp(endDate),
            resourceId: await getResourceIdBasedOnSlotId(slotId),
            hours: originalHours,
        };

        try {
            if (!autoCreateTriggered) {
                setLoading(true);
            }
            await createEvent(event);
            // If event creation is successful, refresh the events
            setRefreshEvents((prev) => !prev); // Toggle the refreshEvents flag
        } catch (error) {
            console.error('Error creating event:', error);
        }
    };

    const addDayAndSkipWeekend = (date) => {
        date.setDate(date.getDate() + 1);
        const dayOfWeek = date.getDay();
        if (dayOfWeek === 6) {
            date.setDate(date.getDate() + 2); // Skip Saturday
        } else if (dayOfWeek === 0) {
            date.setDate(date.getDate() + 1); // Skip Sunday
        }
        return date;
    };

    const calculateNextWorkingDay = (tasks) => {
        if (!Array.isArray(tasks)) {
            throw new Error("Invalid input: tasks must be an array");
        }
    
        let latestEndDate = null;
    
        tasks.forEach((task, index) => {
            if (!task || typeof task.date === "undefined" || typeof task.remainingHours === "undefined") {
                return; // Skip invalid task
            }
    
            const { date, remainingHours } = task;
            const endDate = endDateCalculator(date, remainingHours);
    
            if (!latestEndDate || endDate > latestEndDate) {
                latestEndDate = endDate;
            }
        });
    
        if (latestEndDate) {
            const nextWorkingDay = new Date(latestEndDate);
            return addDayAndSkipWeekend(nextWorkingDay);
        }
    
        return null;
    };

    const endDateCalculator = (startDate, remainingHours) => {
        const endDate = new Date(startDate);
        while (remainingHours > 0) {
            const day = endDate.getDay();
            if (day >= 1 && day <= 5) {
                const hoursToDeduct = Math.min(remainingHours, 9);
                remainingHours -= hoursToDeduct;

                endDate.setHours(endDate.getHours() + hoursToDeduct);

                if (remainingHours > 0) {
                    endDate.setDate(endDate.getDate() + 1);
                    endDate.setHours(8, 0, 0, 0);
                }
            } else {
                endDate.setDate(endDate.getDate() + 1);
                endDate.setHours(8, 0, 0, 0);
            }
        }
        return endDate;
    };

    const autoEventCreator = async () => {
        const listOfEvents = prepareProductEvents();
        setAutoCreateTriggered(true);
        setLoading(true);
        try {
            // Process each event sequentially
            for (const event of listOfEvents) {
                await newEvent(null, event.resourceId, null, event.date);
            }
        } catch (error) {
            console.error('Error creating events:', error);
            // Optionally, handle the error (show a message, etc.)
        } finally {
            setAutoCreateTriggered(false);
        }
    };

    const prepareProductEvents = () => {
        const resultList = [];
        const hoursOfStationsForProduct = extractHoursForStations(props.product);

        hoursOfStationsForProduct.forEach((station) => {
            if (station.key !== 'montaj') {
                const stationHour = parseInt(station.hour);
                if (stationHour === 0) {
                    return;
                }
    
                let possibleStartDatesForMultipleStations = [];
    
                station.ids.forEach((id) => {
                    const startDate = findAvailableSlotAndReturnStartDate(id, stationHour);
                    possibleStartDatesForMultipleStations.push({
                        key: station.key,
                        resourceId: id,
                        date: startDate,
                        remainingHours: station.hour,
                    });
                });
    
                resultList.push(returnEarliestDate(possibleStartDatesForMultipleStations));
            }
        });

        // handle montaj
        const montajIds = [19, 20];
        const montajPossibleDates = [];
        const montajHours = hoursOfStationsForProduct.find(p => p.key === 'montaj').hour;
        const montajShouldStartFromThisDayOrFurther = calculateMontajStartDate(resultList);
        
        montajIds.forEach((id) => {
            const startDate = findAvailableSlotAndReturnStartDate(id, montajHours, montajShouldStartFromThisDayOrFurther);
            montajPossibleDates.push({
                key: 'montaj',
                resourceId: id,
                date: startDate,
                remainingHours: montajHours,
            });
        });

        resultList.push(returnEarliestDate(montajPossibleDates));

        return resultList;
    };

    const calculateMontajStartDate = (orderEvents) => {
        const montajStartDate = calculateNextWorkingDay(orderEvents);
        return montajStartDate;
    }

    const returnEarliestDate = (listOfDates) => {
        const sortedDates = listOfDates.sort((a, b) => new Date(a.date) - new Date(b.date));
        return sortedDates[0];
    };

    const findAvailableSlotAndReturnStartDate = (id, hour, startFrom = null) => {
        let stopCheck = false;
        let startDate = '';
    
        groupedEvents.forEach((item) => {
            if (id === parseInt(item.resourceId)) {
                const filteredEvents = item.items.filter((event) => {
                    if (startFrom) {
                        const startFromDate = new Date(startFrom);
                        startFromDate.setHours(0, 0, 0, 0); // Normalize to start of the day
        
                        const eventStartDate = new Date(event.start);
                        eventStartDate.setHours(0, 0, 0, 0); // Normalize event start to start of the day
        
                        const eventEndDate = new Date(event.end);
                        eventEndDate.setHours(0, 0, 0, 0); // Normalize event end to start of the day
        
                        // Compare only the dates
                        return eventStartDate >= startFromDate || eventEndDate >= startFromDate;
                    }
                    return true; // No filtering if startFrom is not provided
                });
        
                filteredEvents.forEach((event) => {
                    if (!stopCheck) {
                        if (hour <= event.availableHours) {
                            stopCheck = true;
                            startDate = dateCorrector(event.end);
                        }
                    }
                });
            }
        });
        
    
        // If no startDate is found and startFrom is provided, use startFrom as the fallback
        if (startDate === '') {
            if (startFrom) {
                startDate = startFrom;
            } else {
                startDate = dateCorrector(new Date().toISOString());
            }
        }
        return startDate;
    };

    const dateCorrector = (date) => {
        let parsedDate = new Date(date.replace(' ', 'T'));

        // Move to the next day
        parsedDate.setDate(parsedDate.getDate() + 1);

        // Check if the new day is Saturday or Sunday, move to the following Monday
        if (parsedDate.getDay() === 6) {
            // Saturday
            parsedDate.setDate(parsedDate.getDate() + 2);
        } else if (parsedDate.getDay() === 0) {
            // Sunday
            parsedDate.setDate(parsedDate.getDate() + 1);
        }

        // Set the time to 8:00 AM
        parsedDate.setHours(8, 0, 0, 0);

        // Format the date back to "YYYY-MM-DD HH:mm" format
        const year = parsedDate.getFullYear();
        const month = String(parsedDate.getMonth() + 1).padStart(2, '0');
        const day = String(parsedDate.getDate()).padStart(2, '0');
        const hours = String(parsedDate.getHours()).padStart(2, '0');
        const minutes = String(parsedDate.getMinutes()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}`;
    };

    const getDeliveryDate = () => {
        const prepList = prepareProductEvents();
        try {
            const lastStation = prepList.find((s) => s.key === 'montaj');
            const endDate = endDateCalculator(lastStation.date, props.product.montajHours)
                .toISOString()
                .slice(0, 10);

            const formattedEndDate = new Date(endDate);

            setDeliveryDate(formattedEndDate.toLocaleDateString('tr-TR', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            }));

            // Check if lastStation was found
            if (!lastStation) {
                throw new Error("Station 'montaj' not found in the list.");
            }
        } catch (error) {}
    };

    const eventClicked = (_, event) => {
        alert(`You just clicked an event: {id: ${event.id}, title: ${event.title}}`);
    };

    const deleteEvents = async () => {
        setLoading(true);
        try {
            await deleteAllEvents();
            setRefreshEvents((prev) => !prev);
        } catch (error) {
            console.error('Error deleting events:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <div className={styles.scheduleButton}>
                        <u><p>En erken teslimat tarihi <b>{deliveryDate}</b></p></u>
                        <Button
                            loading = {loading}
                            buttonText = "Takvime Ekle"
                            onClick={() => autoEventCreator()}
                        />
                        <Button
                            loading = {loading}
                            buttonText = "Etkinlikleri Temizle"
                            onClick={() => deleteEvents()}
                        />
                    </div>
                    <Scheduler
                        schedulerData={schedulerData}
                        prevClick={prevClick}
                        nextClick={nextClick}
                        onViewChange={onViewChange}
                        onSelectDate={onSelectDate}
                        newEvent={newEvent}
                        eventItemClick={eventClicked}
                    />
                </>
            )}
        </>
    );
};

export default withDndContext(SchedulerComponent);
