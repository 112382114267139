import { useEffect, useState } from 'react';
import styles from './tracker.module.scss';
import Selnikel from '../../../Assets/Images/Logos/selnikel.png';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { trackOrder } from '../../../Services/api.service';
import Loader from '../../../Components/Loader/Loader';

const Tracker = () => {
    const params = useParams();
    const [noOrder, setNoOrder] = useState(false);
    const [orderExistsNoEvent, setOrderExistsNoEvent] = useState(false);
    const [progressData, setProgressData] = useState([]);
    const [deliveryDate, setDeliveryDate] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            const data = await trackOrder(params.code);

            if (data['detail']) {
                setNoOrder(true);
            } else {
                setProgressData(data['resultList']);
                if (data['resultList'] && data['resultList'].length < 1) {
                    setOrderExistsNoEvent(true);
                }
                setDeliveryDate(data['deliveryDate']);
            }
            setLoading(false);
        })();
    }, [params.code]);

    return (
        <>
            <Helmet>
                <title>Selnikel - Tracker</title>
                <meta name='description' content='Tracker' />
                <link rel='canonical' href='/tracker' />
            </Helmet>
            {loading ? (
                <Loader />
            ) : (
                <div className={styles.container}>
                    <div className={styles.wrapper}>
                        <a href='/'>
                            <img src={Selnikel} alt='Selnikel' />
                        </a>
                        {noOrder ? (
                            <p>We don't seem to have an order with that order number.</p>
                        ) : (
                            <>
                                {orderExistsNoEvent ? (
                                    <p>
                                        There is nothing on our schedule for the order number{' '}
                                        <u>{params.code}</u>
                                    </p>
                                ) : (
                                    <p>
                                        Siparis numaraniz <u>{params.code}</u>
                                        <br />
                                        {deliveryDate ? (
                                            <>
                                                Tahmini sevk tarihiniz <u>{deliveryDate}</u>
                                            </>
                                        ) : null}
                                    </p>
                                )}
                            </>
                        )}

                        <div className={styles.card}>
                            <div className={styles.tracker}>
                                {progressData.map((stepData, index) => (
                                    <div key={index} className={styles.step}>
                                        <div
                                            className={styles.circle}
                                            style={{
                                                background: `conic-gradient(#4CAF50 ${stepData.completion}%, #bbb ${stepData.completion}%)`,
                                            }}
                                        >
                                            {stepData.completion === 100 ? '✓' : index + 1}
                                        </div>
                                        <p className={styles.label}>{`${stepData.step}`}</p>
                                        <p className={styles.label}>{`${stepData.completion}%`}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <p className={styles.footerTag}>
                            Copyright © {new Date().getFullYear()} Selnikel. All rights reserved.
                        </p>
                    </div>
                </div>
            )}
        </>
    );
};

export default Tracker;
