import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Pages from '../../../Data/Viewer.json';
import Loader from '../../../Components/Loader/Loader';
import Button from '../../../Components/Button/Button';
import InputField from '../../../Components/InputField/InputField';
import styles from './viewer.module.scss';
import check from '../../../Assets/Images/Icons/Other/checkmark.png';
import xmark from '../../../Assets/Images/Icons/Other/xmark.png';

import {
    getClients,
    getEmployees,
    getOrders,
    getProducts,
    getQuotes,
    getStations,
} from '../../../Services/api.service';
import { unixTimestampToDateTime } from '../../../Components/DatePicker/DatePicker';
import { Helmet } from 'react-helmet-async';
// import Observer from '../../../Components/Observer/Observer';
// import { nextPage } from '../../../Services/storage.handler';

const Viewer = () => {
    const storedKeyword = localStorage.getItem('searchKeyword') || '';
    const userRole = localStorage.getItem('role');
    const navigate = useNavigate();
    const { title } = useParams();
    const [copyList, setCopyList] = useState({});
    const [page, setPage] = useState({});
    const [kwargs, setKwargs] = useState(storedKeyword);
    const [loading, setLoading] = useState(true);

    // special pages
    const [active, setActive] = useState(true);
    const [scheduled, setScheduled] = useState(true);

    let dataRender;
    let buttonRender;

    useEffect(() => {
        (async () => {
            const foundPage = Pages.find((p) => p.title === title);
            foundPage[title] = await functionMap[foundPage.getCall]();
            setPage(foundPage || {});
            setCopyList(foundPage[title]);
            setLoading(false);
        })();
    }, [title]);

    // handle contracts page
    useEffect(() => {
        if (title === 'contracts' && page[title]) {
            setCopyList(page[title].filter((item) => item.isActive === active));
        }
    }, [active, page, title]);

    // handle services page
    useEffect(() => {
        if (title === 'service-manager' && page[title]) {
            if (scheduled) {
                setCopyList(page[title].filter((item) => item.serviceStatus === 0).reverse());
            } else {
                setCopyList(page[title].filter((item) => item.serviceStatus !== 0));
            }
        }
    }, [scheduled, page, title]);

    useEffect(() => {
        const storedValue = localStorage.getItem('searchKeyword');
        if (storedValue) {
            setKwargs(storedValue);
        }
    }, []);

    // Update storage whenever inputValue changes
    useEffect(() => {
        localStorage.setItem('searchKeyword', kwargs);
    }, [kwargs]);

    const handleButtonClick = (button) => {
        const buttonText = Object.keys(button)[0];

        switch (buttonText) {
            case 'Active':
                return setActive(true);
            case 'Inactive':
                return setActive(false);
            case 'Scheduled':
                return setScheduled(true);
            case 'Complete':
                return setScheduled(false);
            default:
                navigate(`/admin/${page['title']}/${Object.values(button)[0]}`);
        }
    };

    if (!loading) {
        dataRender = (copyList || [])
            .filter((listItem) => {
                return Object.values(listItem).some((value) => {
                    return value && value.toString().toLowerCase().includes(kwargs);
                });
            })
            .map((data) => (
                <tr key={data.id} onClick={() => navigate(`/admin/${page['title']}/${data.id}`)}>
                    {Object.keys(data)
                        .filter((key) => !page['ignoreKeys'].includes(key))
                        .map((key) => {
                            if (key === 'sent') {
                                return (
                                    <td key={key}>
                                        {data[key] ? (
                                            <img loading='lazy' src={check} alt='icon' />
                                        ) : (
                                            <img loading='lazy' src={xmark} alt='icon' />
                                        )}
                                    </td>
                                );
                            } else if (key.includes('date') || key.includes('Date')) {
                                return (
                                    <td key={key}>
                                        {data[key] ? unixTimestampToDateTime(data[key]) : '-'}
                                    </td>
                                );
                            } else {
                                return <td key={key}>{data[key] ? data[key] : '-'}</td>;
                            }
                        })}
                </tr>
            ));

        buttonRender = (page['buttons'] || []).map((button) => {
            const buttonText = Object.keys(button)[0];

            return (
                <Button
                    key={buttonText}
                    buttonText={buttonText}
                    onClick={() => handleButtonClick(button)}
                />
            );
        });
    }

    return (
        <>
            <Helmet>
                <meta name='robots' content='noindex' />
            </Helmet>
            <div className={styles.placeholder} />
            {loading ? (
                <Loader />
            ) : (
                <div className={styles.container}>
                    <h1>{page.name}</h1>

                    <div className={styles.inputContainer}>
                        <InputField
                            path='search.png'
                            placeholder='Ara...'
                            value={kwargs}
                            onChange={(e) => setKwargs(kwargsFixer(e.target.value))}
                        />
                    </div>

                    {userRole === 'Admin' ? (
                        <div className={styles.buttonContainer}>{buttonRender}</div>
                    ) : null}

                    <div className={styles.tableWrapper}>
                        <table>
                            <thead>
                                <tr>
                                    {userRole === 'Employee' && page.employeeTableHeader
                                        ? page.employeeTableHeader.map((header) => (
                                              <th key={header}>{header}</th>
                                          ))
                                        : page.tableHeader.map((header) => (
                                              <th key={header}>{header}</th>
                                          ))}
                                </tr>
                            </thead>
                            <tbody>{dataRender}</tbody>
                        </table>
                    </div>
                </div>
            )}
        </>
    );
};

export default Viewer;

const functionMap = {
    getEmployees: getEmployees,
    getClients: getClients,
    getProducts: getProducts,
    getStations: getStations,
    getOrders: getOrders,
    getQuotes: getQuotes,
};

export const kwargsFixer = (kwargs) => {
    return kwargs.replace(/[^a-zA-Z0-9- ]/g, '').toLowerCase();
};
