import { BrowserRouter as Router, Routes, Route, Outlet, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import Navigation from './Components/Navigation/Navigation';
import Login from './Pages/Public/Login/Login';
import NavigationPrivateList from './Data/NavigationPrivate.json';
import { useRecoilState } from 'recoil';
import { loadingState } from './Recoil/Atoms';
import { syncDb } from './Services/api.service';
import Loader from './Components/Loader/Loader';
import Viewer from './Pages/Private/Viewer/Viewer';
import Editor from './Pages/Private/Editor/Editor';
import EventViewer from './Pages/Private/Events/Viewer/EventViewer';
import EventEditor from './Pages/Private/Events/Editor/EventEditor';
import Tracker from './Pages/Public/Tracker/Tracker';
import Quote from './Pages/Public/Quote/Quote';
import OrderConfirmation from './Pages/Public/OrderConfirmation/OrderConfirmation';

const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};

const PrivateLayout = () => (
    <>
        <Navigation menuItems={NavigationPrivateList} type={'Private'} />
        <Outlet />
    </>
);

const App = () => {
    const [loading, setLoading] = useRecoilState(loadingState);

    useEffect(() => {
        (async () => {
            setLoading(true);
            await syncDb();
            setLoading(false);
        })();
    }, [setLoading]);
    return (
        <Router>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <ScrollToTop />
                    <Routes>
                        <Route element={<PrivateLayout />}>
                            <Route path='admin/:title/:id' element={<Editor />} />
                            <Route path='admin/:title' element={<Viewer />} />
                            <Route path='admin/events' element={<EventViewer />} />
                            <Route path='admin/events/:id' element={<EventEditor />} />
                        </Route>
                        <Route>
                            <Route exact path='/' element={<Login />} />
                            <Route path='*' element={<Login />} />
                        </Route>
                        <Route path='login' element={<Login />} />
                        <Route path='quote' element={<Quote />} />
                        <Route path='order-confirmation/:id/:r' element={<OrderConfirmation />} />
                        <Route path='tracker/:code' element={<Tracker />} />
                    </Routes>
                </>
            )}
        </Router>
    );
};

export default App;
