import styles from './dropdown.module.scss';
import {
    getEmployees,
    getClients,
    filterActiveClients,
    getProducts,
    getOrders,
} from '../../Services/api.service';
import { useEffect, useState } from 'react';

const Dropdown = (props) => {
    const [dropdownItems, setDropdownItems] = useState([]);

    useEffect(() => {
        (async () => {
            const result = await functionMap[props.options]();
            setDropdownItems(result || []);
        })();
    }, [props.options]);

    return (
        <div className={styles.container}>
            <p>
                <b>{props.label}</b>
            </p>
            <select value={props.value} onChange={props.onChange} disabled={props.disabled}>
                <option value={-1} disabled='disabled'>
                    {props.label} Seç
                </option>
                {dropdownItems.map((item, index) => (
                    <option key={index} value={item.id}>
                        {item[props.displayKey[0]] && item[props.displayKey[1]]
                            ? item[props.displayKey[0]] + ' - ' + item[props.displayKey[1]]
                            : item[props.displayKey[0]]
                              ? item[props.displayKey[0]]
                              : item[props.displayKey[1]]}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default Dropdown;

const functionMap = {
    getEmployees: getEmployees,
    getClients: getClients,
    filterActiveClients: filterActiveClients,
    getProducts: getProducts,
    getOrders: getOrders,
};
