import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './event-viewer.module.scss';
import Loader from '../../../../Components/Loader/Loader';
import { Helmet } from 'react-helmet-async';
import { kwargsFixer } from '../../Viewer/Viewer';
import { unixTimestampToDateTime } from '../../../../Components/DatePicker/DatePicker';
import { getEvents } from '../../../../Services/api.service';
import InputField from '../../../../Components/InputField/InputField';
import Button from '../../../../Components/Button/Button';

function EventViewer() {
    const storedKeyword = localStorage.getItem('searchKeyword') || '';
    const [kwargs, setKwargs] = useState(storedKeyword);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [events, setEvents] = useState([]);

    useEffect(() => {
        (async () => {
            const eventsObj = await getEvents();
            setEvents(eventsObj['events']);
            setLoading(false);
        })();
    }, []);

    let filteredData = eventSearch(events, kwargs);

    let dataRender = filteredData.map((data) => {
        return (
            <tr key={data.id} onClick={() => navigate(`/admin/events/${data.orderId}`)}>
                <td># {data.id}</td>
                <td>{data.orderNumber}</td>
                <td>{unixTimestampToDateTime(data.start)}</td>
                <td>{data.resourceTitle}</td>
            </tr>
        );
    });

    return (
        <>
            <Helmet>
                <meta name='robots' content='noindex' />
            </Helmet>
            <div className={styles.placeholder} />
            {loading ? (
                <Loader />
            ) : (
                <div className={styles.container}>
                    <h1>Takvim</h1>

                    <div className={styles.inputContainer}>
                        <InputField
                            path='search.png'
                            placeholder='Ara...'
                            value={kwargs}
                            onChange={(e) => setKwargs(kwargsFixer(e.target.value))}
                        />
                    </div>

                    <div className={styles.buttonContainer}>
                        <Button buttonText='Create' onClick={() => navigate('/admin/events/new')} />
                    </div>

                    <div className={styles.tableWrapper}>
                        <table>
                            <thead>
                                <tr>
                                    <th>Etkinlik</th>
                                    <th>Sipariş</th>
                                    <th>Planlanan Tarih</th>
                                    <th>Tip</th>
                                </tr>
                            </thead>
                            <tbody>{dataRender}</tbody>
                        </table>
                    </div>
                </div>
            )}
        </>
    );
}

export default EventViewer;

const eventSearch = (targetList, kwargs) => {
    return targetList.filter(
        (event) =>
            event.id?.toString().match(kwargs) ||
            event.orderNumber?.toLowerCase().match(kwargs) ||
            event.resourceTitle?.toLowerCase().match(kwargs),
    );
};
