import axios from 'axios';
import { getItem, setItem, getEntity } from './storage.handler';

const apiUrl = process.env.REACT_APP_API_URL;
//#region Authentication

/** Authentication header */

const token = window.localStorage.getItem('token');
axios.defaults.headers = {};
const pathNames = ['login', 'tracker', 'quote', 'order-confirmation', ''];
const path = window.location.pathname.split('/')[1];

if (!token && path !== '/') {
    if (!pathNames.includes(path)) {
        window.location.replace('/');
    }
} else {
    axios.defaults.headers = {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
    };
}

export const syncDb = async (force = false) => {
    if (localStorage.getItem('syncTs')) {
        const lastSync = new Date(localStorage.getItem('syncTs'));
        const since = Math.abs(new Date() - lastSync) / 36e5;

        if (since > 1 || force) {
            await getClients(true);
            //await getEmployees(true);
            await getEvents(true);
            await getOrders(true);
            await getProducts(true);
            await getStations(true);
            await getQuotes(true);
            localStorage.setItem('syncTs', new Date());
        }
    }
};

//#endregion

//#region Clients

export const getClient = async (id) => {
    await getClients();
    return getEntity('clients', id);
};

export const getClients = async (force = false) => {
    if (!getItem('clients') || force) {
        await axios.get(apiUrl + `clients/`).then((response) => {
            const clientsWithNoCompany = response.data
                .filter((c) => c.companyName === null)
                .sort((a, b) => a.fullName.localeCompare(b.fullName));
            const companies = response.data
                .filter((c) => c.companyName !== null)
                .sort((a, b) => a.companyName.localeCompare(b.companyName));
            setItem('clients', companies.concat(clientsWithNoCompany));
        });
    }

    return getItem('clients');
};

export const filterActiveClients = async () => {
    await getClients(); // Assuming this populates the local storage
    let clientsFromStorage = getItem('clients'); // Retrieve the clients from local storage

    // Filter the clients to only include active ones
    let activeClients = clientsFromStorage.filter((client) => client.isActive);
    return activeClients;
};

export const updateClient = async (id, data) => {
    const response = await axios.put(apiUrl + `client/${id}/`, data);
    await getClients(true);
    return response;
};

export const createClient = async (data) => {
    const response = await axios.post(apiUrl + `clients/create`, data);
    await getClients(true);
    return response;
};

export const deleteClient = async (id) => {
    const response = await axios.delete(apiUrl + `client/delete/${id}/`);
    await getClients(true);
    return response;
};

//#endregion

//#region Employees

export const getEmployee = async (id) => {
    await getEmployees();
    return getEntity('employees', id);
};

export const getEmployees = async (force = false) => {
    if (!getItem('employees') || force) {
        await axios
            .get(apiUrl + `employees/`)
            .then((response) => setItem('employees', response.data));
    }

    return getItem('employees');
};

export const updateEmployee = async (id, data) => {
    const response = await axios.put(apiUrl + `employee/${id}/`, data);
    await getEmployees(true);
    return response;
};

export const createEmployee = async (data) => {
    const response = await axios.post(apiUrl + `employees/create`, data);
    await getEmployees(true);
    return response;
};

export const deleteEmployee = async (id) => {
    const response = await axios.delete(apiUrl + `employee/delete/${id}`);
    await getEmployees(true);
    return response;
};

//#endregion

//#region Events

export const getEvent = async (id) => {
    await getEvents();
    return getEntity('events', id);
};

export const getEvents = async (force = false) => {
    if (!getItem('events') || force) {
        await axios.get(apiUrl + `events/`).then((response) => setItem('events', response.data));
    }

    return getItem('events');
};

export const updateEvent = async (id, data) => {
    const response = await axios.put(apiUrl + `event/${id}/`, data);
    await getEvents(true);
    return response;
};

export const createEvent = async (data) => {
    const response = await axios.post(apiUrl + `events/create`, data);
    await getEvents(true);
    return response;
};

export const deleteEvent = async (id) => {
    const response = await axios.delete(apiUrl + `event/delete/${id}`);
    await getEvents(true);
    return response;
};

export const deleteAllEvents = async () => {
    const response = await axios.delete(apiUrl + `event/delete-all/`);
    await getEvents(true);
    return response;
};

//#endregion

//#region Orders

export const getOrder = async (id) => {
    await getOrders();
    return getEntity('orders', id);
};

export const getOrders = async (force = false) => {
    if (!getItem('orders') || force) {
        await axios.get(apiUrl + `orders/`).then((response) => setItem('orders', response.data));
    }

    return getItem('orders');
};

export const updateOrder = async (id, data) => {
    const response = await axios.put(apiUrl + `order/${id}/`, data);
    await getOrders(true);
    return response;
};

export const createOrder = async (data) => {
    const response = await axios.post(apiUrl + `orders/create`, data);
    await getOrders(true);
    return response;
};

export const deleteOrder = async (id) => {
    const response = await axios.delete(apiUrl + `order/delete/${id}`);
    await getOrders(true);
    await getEvents(true);
    return response;
};

export const trackOrder = async (secret) => {
    await axios
        .get(apiUrl + `track/${secret}`)
        .then((response) => {
            if (response.status === 200) {
                setItem('tracker', response.data);
            }
        })
        .catch((error) => {
            if (error.response.status === 404) {
                setItem('tracker', error.response.data);
            }
        });

    return getItem('tracker');
};

//#endregion

//#region Products

export const getProduct = async (id) => {
    await getProducts();
    return getEntity('products', id);
};

export const getProducts = async (force = false) => {
    if (!getItem('products') || force) {
        await axios
            .get(apiUrl + `products/`)
            .then((response) => setItem('products', response.data));
    }

    return getItem('products');
};

export const updateProduct = async (id, data) => {
    const response = await axios.put(apiUrl + `product/${id}/`, data);
    await getProducts(true);
    return response;
};

export const createProduct = async (data) => {
    const response = await axios.post(apiUrl + `products/create`, data);
    await getProducts(true);
    return response;
};

export const deleteProduct = async (id) => {
    const response = await axios.delete(apiUrl + `product/delete/${id}`);
    await getProducts(true);
    return response;
};

//#endregion

//#region Stations

export const getStation = async (id) => {
    await getStations();
    return getEntity('stations', id);
};

export const getStations = async (force = false) => {
    if (!getItem('stations') || force) {
        await axios
            .get(apiUrl + `stations/`)
            .then((response) => setItem('stations', response.data));
    }

    return getItem('stations');
};

export const updateStation = async (id, data) => {
    const response = await axios.put(apiUrl + `station/${id}/`, data);
    await getStations(true);
    return response;
};

export const createStation = async (data) => {
    const response = await axios.post(apiUrl + `stations/create`, data);
    await getStations(true);
    return response;
};

export const deleteStation = async (id) => {
    const response = await axios.delete(apiUrl + `station/delete/${id}`);
    await getStations(true);
    return response;
};

//#endregion

//#region Quotes

export const getQuote = async (id, hasRetried = false) => {
    // Attempt to get quotes with or without retry
    await getQuotes(hasRetried);

    const result = getEntity('quotes', id);

    if (result === undefined && !hasRetried) {
        return await getQuote(id, true);
    }

    return result;
};

export const getQuotes = async (force = false) => {
    if (!getItem('quotes') || force) {
        await axios.get(apiUrl + `quotes/`).then((response) => setItem('quotes', response.data));
    }

    return getItem('quotes');
};

export const updateQuote = async (id, data) => {
    const response = await axios.put(apiUrl + `quote/${id}/`, data);
    await getQuotes(true);
    return response;
};

export const createQuote = async (data) => {
    const response = await axios.post(apiUrl + `quotes/create`, data);
    await getQuotes(true);
    return response;
};

export const deleteQuote = async (id) => {
    const response = await axios.delete(apiUrl + `quote/delete/${id}`);
    await getQuotes(true);
    return response;
};

export const sendQuote = async (id, data) => {
    const response = await axios.put(apiUrl + `send-quote/${id}/`, data);
    await getQuotes(true);
    return response;
};

export const requestQuote = async (data) => {
    return axios.post(apiUrl + `request-quote/`, data);
};

export const quoteResponse = async (id, r) => {
    return axios.get(apiUrl + `quote-response/${id}/${r}/`);
};

//#endregion
