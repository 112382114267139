import { useNavigate, useParams } from 'react-router-dom';
import Pages from '../../../Data/Editor.json';
import { useEffect, useState } from 'react';
import {
    deleteClient,
    getClient,
    getEmployee,
    updateClient,
    updateEmployee,
    deleteEmployee,
    createClient,
    createEmployee,
    getStation,
    updateStation,
    deleteStation,
    createStation,
    getProduct,
    updateProduct,
    deleteProduct,
    createProduct,
    getOrder,
    updateOrder,
    deleteOrder,
    createOrder,
    getQuote,
    updateQuote,
    deleteQuote,
    createQuote,
    sendQuote,
} from '../../../Services/api.service';
import InputField from '../../../Components/InputField/InputField';
import Checkbox from '../../../Components/Checkbox/Checkbox';
import Dropdown from '../../../Components/Dropdown/Dropdown';
import TextArea from '../../../Components/TextArea/TextArea';
import Button from '../../../Components/Button/Button';
import Loader from '../../../Components/Loader/Loader';
import styles from './editor.module.scss';
import DatePicker, { dateToUnixTimeStamp } from '../../../Components/DatePicker/DatePicker';
import { Helmet } from 'react-helmet-async';

const Editor = () => {
    const role = localStorage.getItem('role');
    const navigate = useNavigate();
    const { title, id } = useParams();
    const [page, setPage] = useState({});
    const [currentItem, setCurrentItem] = useState({});
    const [loading, setLoading] = useState(true);

    const functionMap = {
        getEmployee: { func: getEmployee, params: [id] },
        updateEmployee: { func: updateEmployee, params: [id, currentItem] },
        deleteEmployee: { func: deleteEmployee, params: [id] },
        createEmployee: { func: createEmployee, params: [{ ...currentItem }] },
        getClient: { func: getClient, params: [id] },
        updateClient: { func: updateClient, params: [id, currentItem] },
        deleteClient: { func: deleteClient, params: [id] },
        createClient: { func: createClient, params: [{ ...currentItem }] },
        getStation: { func: getStation, params: [id] },
        updateStation: { func: updateStation, params: [id, currentItem] },
        deleteStation: { func: deleteStation, params: [id] },
        createStation: { func: createStation, params: [{ ...currentItem }] },
        getProduct: { func: getProduct, params: [id] },
        updateProduct: { func: updateProduct, params: [id, currentItem] },
        deleteProduct: { func: deleteProduct, params: [id] },
        createProduct: { func: createProduct, params: [{ ...currentItem }] },
        getOrder: { func: getOrder, params: [id] },
        updateOrder: { func: updateOrder, params: [id, currentItem] },
        deleteOrder: { func: deleteOrder, params: [id] },
        createOrder: { func: createOrder, params: [{ ...currentItem }] },
        getQuote: { func: getQuote, params: [id] },
        updateQuote: { func: updateQuote, params: [id, currentItem] },
        deleteQuote: { func: deleteQuote, params: [id] },
        createQuote: { func: createQuote, params: [{ ...currentItem }] },
        sendQuote: { func: sendQuote, params: [id, currentItem] },
    };

    useEffect(() => {
        (async () => {
            const foundPage = Pages.find((p) => p.title === title);
            setPage(foundPage || {});
            if (id !== 'new') {
                const { func, params } = functionMap[foundPage.getCall] || {};

                if (func) {
                    setCurrentItem(await func(...params));
                } else {
                    console.error(`Function not found for action: ${foundPage.getCall}`);
                }
            } else {
                const { createTs, orderNumber, ...restInputFields } = foundPage['inputFields'];
                setCurrentItem(
                    Object.fromEntries(Object.keys(restInputFields).map((key) => [key, ''])),
                );
            }
            setLoading(false);
        })();
        // eslint-disable-next-line
    }, [title, id]);

    const getCustomerInfo = async (id) => {
        const client = await getClient(id);
        return { address: client.address, city: client.city, postalCode: client.postalCode };
    };

    const inputRenderHelper = (key, value) => {
        if (page['inputFields'][key]) {
            const inputObj = page['inputFields'][key];
            const label = inputObj['label'];
            const type = inputObj['type'];
            const disabled = inputObj['disabled'];
            const dropdownCall = inputObj['dropdownCall'];
            const displayKey = inputObj['displayKey'];

            switch (type) {
                case 'input':
                    return (
                        <InputField
                            key={key}
                            label={label}
                            disabled={disabled || role !== 'Admin'}
                            value={value ? value : ''}
                            onChange={(e) => {
                                setCurrentItem((prev) => ({
                                    ...prev,
                                    [key]: e.target.value,
                                }));
                            }}
                        />
                    );
                case 'dropdown':
                    return (
                        <Dropdown
                            key={key}
                            label={label}
                            value={value ? value : -1}
                            options={dropdownCall}
                            displayKey={displayKey}
                            onChange={async (e) => {
                                setCurrentItem((prev) => ({
                                    ...prev,
                                    [key]: e.target.value,
                                }));

                                if (label === 'Client') {
                                    const clientInfo = await getCustomerInfo(e.target.value);
                                    setCurrentItem((prev) => ({
                                        ...prev,
                                        address: clientInfo.address,
                                        city: clientInfo.city,
                                        postalCode: clientInfo.postalCode,
                                    }));
                                }
                            }}
                            disabled={label !== 'Employee' && role !== 'Admin'}
                        />
                    );
                case 'textarea':
                    return (
                        <TextArea
                            key={key}
                            label={label}
                            disabled={disabled}
                            value={value ? value : ''}
                            onChange={(e) => {
                                setCurrentItem((prev) => ({
                                    ...prev,
                                    [key]: e.target.value,
                                }));
                            }}
                        />
                    );
                case 'checkbox':
                    if (value === undefined || value === '') {
                        setCurrentItem((prev) => ({
                            ...prev,
                            [key]: false,
                        }));
                    }
                    return (
                        <Checkbox
                            key={key}
                            label={label}
                            checked={value ? value : false}
                            onChange={(e) => {
                                setCurrentItem((prev) => ({
                                    ...prev,
                                    [key]: e.target.checked,
                                }));
                            }}
                        />
                    );
                case 'date':
                    return (
                        <DatePicker
                            key={key}
                            initialTimestamp={value ? value : dateToUnixTimeStamp(new Date())}
                            disabled={disabled || role !== 'Admin'}
                            label={label}
                            onChange={(unixTimeStamp) => {
                                setCurrentItem((prev) => ({
                                    ...prev,
                                    [key]: unixTimeStamp,
                                }));
                            }}
                        />
                    );
                default:
                    return <h1>{type} needs a new component</h1>;
            }
        }
    };

    const renderInputFields = () => {
        if (page['ignoreKeys']) {
            return (
                <>
                    {Object.entries(currentItem)
                        .filter(([key]) => !page['ignoreKeys'].includes(key))
                        .map(([key, value]) => inputRenderHelper(key, value))}
                </>
            );
        }
    };

    const createFunction = async (action) => {
        setLoading(true);
        if (functionMap[action]) {
            const { func, params } = functionMap[action];
            try {
                await func(...params);
                navigate(-1);
            } catch (error) {
                console.error(`Error calling function ${action}: ${error.message}`);
            }
        } else {
            console.error(`Function not found for action: ${action}`);
        }
        setLoading(false);
    };

    const renderButtons = () => {
        if (page['buttons']) {
            return (
                <>
                    {page['buttons'].map((button, index) => (
                        <Button
                            key={index}
                            onClick={() => createFunction([Object.values(button)[0]])}
                            buttonText={Object.keys(button)[0]}
                            disabled={Object.keys(button)[0] !== 'Update' && role !== 'Admin'}
                        />
                    ))}
                </>
            );
        }
    };

    return (
        <>
            <Helmet>
                <meta name='robots' content='noindex' />
            </Helmet>
            <div className={styles.placeholder} />
            {loading ? (
                <Loader />
            ) : (
                <div className={styles.container}>
                    <h1>{page.name} Düzenleme</h1>
                    <div className={styles.inputContainer}>{renderInputFields()}</div>
                    <div className={styles.buttonContainer}>
                        {id === 'new' ? (
                            <Button
                                buttonText='Oluştur'
                                onClick={() => createFunction(page['createCall'])}
                            />
                        ) : (
                            renderButtons()
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default Editor;
