import React, { useEffect, useState } from 'react';
import styles from './event-editor.module.scss';
import Loader from '../../../../Components/Loader/Loader';
import { Helmet } from 'react-helmet-async';
import Dropdown from '../../../../Components/Dropdown/Dropdown';
import { getOrder, getProduct } from '../../../../Services/api.service';
import TextArea from '../../../../Components/TextArea/TextArea';
import SchedulerComponent from '../../../../Components/Scheduler/SchedulerComponent';
import { useParams } from 'react-router-dom';

function EventEditor() {
    const [order, setOrder] = useState();
    const { id } = useParams();
    const [orderObj, setOrderObj] = useState();
    const [product, setProduct] = useState();
    const [loading] = useState(false);
    const [resultText, setResultText] = useState('');

    useEffect(() => {
        (async () => {
            if (id !== 'new') {
                await onOrderSelect(id);
            }
        })();
    }, [id]);

    const onOrderSelect = async (value) => {
        setOrder(value);
        const orderById = await getOrder(value);
        const currentProduct = await getProduct(orderById.productId);
        setOrderObj(orderById);
        setProduct(currentProduct);
        setResultText(
            `\nSeçilen ürün: ${currentProduct.title} \n\nBalans ${currentProduct.balansHours} saat\n\nEmis Hunisi ${currentProduct.emisHunisiHours} saat\n\nGovde ${currentProduct.govdeHours} saat\n\nRotor ${currentProduct.rotorHours} saat\n\nTalasli Imalat ${currentProduct.talasliImalatHours} saat\n\nYatak Alti ${currentProduct.yatakAltiHours} saat\n\nMontaj ${currentProduct.montajHours} saat\n\n`,
        );
    };

    let allowedResources = [];
    if (product) {
        allowedResources = Object.keys(product).filter((key) => product[key] === true);
    }

    return (
        <>
            <Helmet>
                <meta name='robots' content='noindex' />
            </Helmet>
            <div className={styles.placeholder} />
            {loading ? (
                <Loader />
            ) : (
                <>
                    <div className={styles.container}>
                        <h1>Takvim Düzenleme</h1>
                        <div className={styles.inputContainer}>
                            <Dropdown
                                value={order ? order : -1}
                                label='Sipariş'
                                options={'getOrders'}
                                displayKey={['orderNumber']}
                                onChange={async (e) => {
                                    onOrderSelect(e.target.value);
                                }}
                            />
                            <TextArea label='Detaylar' value={resultText} disabled={true} />
                        </div>
                        {order ? (
                            <SchedulerComponent
                                allowedResources={allowedResources}
                                product={product}
                                order={orderObj}
                            />
                        ) : null}
                    </div>
                </>
            )}
        </>
    );
}

export default EventEditor;
